@import "~@kaizen/design-tokens/sass/spacing";
@import "~@kaizen/design-tokens/sass/color";

.container {
  height: 100%;
  padding: $spacing-lg;
  border-radius: inherit;
}

.cautionary {
  background-color: $color-yellow-100;
}

.negative {
  background-color: $color-red-100;
}
