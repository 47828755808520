@import "~@kaizen/design-tokens/sass/spacing";
@import "~@kaizen/design-tokens/sass/layout";

.container {
  display: flex;
  flex-direction: row;

  @media (max-width: ($layout-breakpoints-medium - 1px)) {
    flex-direction: column;
    justify-content: center;
  }
}

.header {
  flex-grow: 2;

  @media (max-width: ($layout-breakpoints-medium - 1px)) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacing-md;
  }
}

.description {
  @media (max-width: ($layout-breakpoints-medium - 1px)) {
    text-align: center;
  }
}

.stats {
  width: 100%;
  align-self: center;
  flex-basis: 180px;

  @media (max-width: ($layout-breakpoints-medium - 1px)) {
    margin-bottom: $spacing-md;
    flex-basis: unset;
  }
}

.cta {
  white-space: nowrap;
  align-self: center;
}
