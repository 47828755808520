@import "~@kaizen/design-tokens/sass/border";
@import "~@kaizen/design-tokens/sass/color";

.listContainer {
  width: auto;
  border-radius: $border-borderless-border-radius;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
