@import "~@kaizen/design-tokens/sass/spacing";
@import "~@kaizen/design-tokens/sass/layout";

$spacer-gap-limit: 5;

@for $i from 1 through $spacer-gap-limit {
  .gap#{$i} {
    margin-bottom: calc(#{$spacing-md} * #{$i});
  }
}

@for $i from 1 through $spacer-gap-limit {
  .gapOnMediumViewports#{$i} {
    @media (max-width: ($layout-breakpoints-large - 1px)) {
      margin-bottom: calc(#{$spacing-md} * #{$i});
    }
  }
}

@for $i from 1 through $spacer-gap-limit {
  .gapOnSmallViewports#{$i} {
    @media (max-width: ($layout-breakpoints-medium - 1px)) {
      margin-bottom: calc(#{$spacing-md} * #{$i});
    }
  }
}
