@import "~@kaizen/design-tokens/sass/spacing";
@import "~@kaizen/design-tokens/sass/typography";

.statistic {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}

.left {
  text-align: left;

  [dir="rtl"] & {
    text-align: right;
  }
}

.center {
  text-align: center;
}

.right {
  text-align: right;

  [dir="rtl"] & {
    text-align: left;
  }
}

.iconWithText {
  p {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: $spacing-xs;
  }

  svg {
    position: relative;
    top: 3px;
  }
}

.value {
  font-family: $typography-data-medium-font-family;
  font-size: $typography-data-medium-font-size;
  font-weight: $typography-data-medium-font-weight;
  letter-spacing: $typography-data-medium-letter-spacing;
  line-height: 0.8; // remove the top spacing
  white-space: nowrap;

  p {
    position: relative;
    bottom: -4px;
    display: inline-flex;
    align-items: center;
    margin-left: $spacing-xs;

    [dir="rtl"] & {
      margin-left: 0;
      margin-right: $spacing-xs;
    }
  }
}

.units {
  font-family: $typography-data-medium-units-font-family;
  font-size: $typography-data-medium-units-font-size;
  font-weight: $typography-data-medium-units-font-weight;
  letter-spacing: $typography-data-medium-units-letter-spacing;
}

.label {
  line-height: $typography-paragraph-extra-small-line-height;
}
