@import "~@kaizen/design-tokens/sass/spacing";
@import "~@kaizen/design-tokens/sass/layout";

.container {
  margin-top: $spacing-md;

  @media (min-width: $layout-breakpoints-medium) {
    margin-top: 0;
  }
}

.col-1 {
  width: 100%;
}

.col-2 {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-gap: $spacing-md;

  @media (min-width: $layout-breakpoints-medium) {
    // this is a hot fix purposely haven't updated the styles above outside of media query due to Home Connect update
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 1 1 calc(50% - #{$spacing-md});
      max-width: unset;
    }
  }
}

.heading {
  padding: $spacing-md 0;

  @media (max-width: #{$layout-breakpoints-large - 1px}) {
    padding: $spacing-md;
  }
}

.onlyLarge {
  @media (max-width: #{$layout-breakpoints-large - 1px}) {
    display: none;
  }
}

.onlyMediumAndSmall {
  display: none;

  @media (max-width: #{$layout-breakpoints-large - 1px}) {
    display: block;
  }
}

.hideSkirtOnMobileAndTablet {
  @media (max-width: #{$layout-breakpoints-large - 1px}) {
    & > div:first-child {
      display: none;
    }
  }
}
